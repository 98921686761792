import { ReactNode } from "react";

import { AppFooter } from "@/components/AppFooter";
import { usePage } from "@inertiajs/react";

import { Flex } from "@chakra-ui/react";

import ParentsNav from "@components/ParentsNav/ParentsNav.tsx";

interface PropTypes {
    children: ReactNode;
    backgroundColor?: string;
    maxW?: string | number;
    isEnableMaxH?: boolean;
    showNav?: boolean;
}

const ParentPortalLayout = ({
    children,
    backgroundColor = "var(--euka-colors-white-500)",
    maxW = "1280px",
    isEnableMaxH = false,
    showNav = true
}: PropTypes) => {
    // version text being passed from backend...
    const { versionText } = usePage().props;

    const getVersionText = (): string => {
        return versionText as string;
    };

    return (
        <Flex
            id="portal-layout"
            direction="column"
            backgroundColor={backgroundColor}
            minH="100dvh"
            maxH={{ base: "none", md: isEnableMaxH ? "100dvh" : "none" }}
            overflow="hidden"
            data-testid="parent-portal-layout"
        >
            {showNav && <ParentsNav />}
            <Flex
                direction="column"
                maxW={maxW}
                width="100%"
                mx="auto"
                flex={1}
                position="relative"
                overflow="hidden"
            >
                {children}
            </Flex>
            {versionText !== null && (
                <AppFooter versionText={getVersionText()} />
            )}
        </Flex>
    );
};

export default ParentPortalLayout;
